import { ChangeDetectionStrategy, Component, inject, NgZone, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { LanguageService } from './translate/language.service';
import { AuthenticationService } from "./services/authentication/authentication.service";
import { EnvironmentStore } from "./stores/environment/environment.store";
import { SpinnerComponent } from "./svg/spinner/spinner.component";
import { ColorSchemeService } from "./services/styles/color-scheme.service";
import { PostMessageService } from './global-events/PostMessage/post-message.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, SpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
    title = 'RobocodeApp';

    private environmentStore = inject(EnvironmentStore);

    protected isDataLoaded: Signal<boolean> = this.environmentStore.isDataLoaded;

    constructor(
            private languageService: LanguageService,
            private authenticationService: AuthenticationService,
            private ngZone: NgZone,
            private router: Router,
            private colorSchemeService: ColorSchemeService,
            private postMessageService: PostMessageService,
    ) {
        this.colorSchemeService.load();
        this.postMessageService.enablePostMessageEvent();
        this.languageService.langDataLoaded().subscribe(data => {
            ngZone.run(() => {
                router.initialNavigation();
            });
        });
        this.environmentStore.loadClientData();
    }
}
