<div class="subscription-popup" tabindex="0">
    <div class="subscription-popup__description">
        <span>Работа виджета приостановлена.</span><br>
        <span>Подписка закончилась {{ expirationDate }}</span>
    </div>
    <div class="subscription-popup__buttons-wrapper">
        <button amo-save-button role="add" (click)="OnProlongSubscriptionClick()">
            <span class="page_text-uppercase">Продлить подписку</span>
        </button>
    </div>
</div>
<robocode-default-overlay></robocode-default-overlay>
