import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SmartButtonsStore } from '../stores/smart-buttons/smart-buttons.store';
import { combineLatest, filter, first, mergeMap, of, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { EnvironmentStore } from '../../../../stores/environment/environment.store';
import { patchState } from '@ngrx/signals';
import { ScriptsStore } from "../../../../stores/scripts/scripts.store";

export const tabPageGuardGuard: CanActivateFn = (route, state) => {

    let environmentStore = inject(EnvironmentStore);
    let smartButtonsStore = inject(SmartButtonsStore);
    let scriptsStore = inject(ScriptsStore);
    smartButtonsStore.loadSmartButtons();
    scriptsStore.loadScenarios();

    return combineLatest([
        toObservable(smartButtonsStore.isLoaded),
        toObservable(scriptsStore.isLoaded),
    ]).pipe(
        filter(([isSmartButtonsLoaded, isScriptLoaded]: [isSmartButtonsLoaded: boolean, isScriptLoaded: boolean]) => {
            return (isSmartButtonsLoaded && isScriptLoaded);
        }),
        first(),
        tap(() => {
            patchState(environmentStore, { isDataLoaded: true });
        }),
        mergeMap(() => {
            return of(true);
        })
    )
};
