import { Injectable, Injector } from '@angular/core';
import { SubscriptionPopupComponent } from "../subscription-popup.component";
import { DefaultPopup } from "../../../helpers/base-ae/default-popup.class";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPopupService extends DefaultPopup<SubscriptionPopupComponent> {
    constructor(private injector: Injector) {
        super('robocode-subscription-popup-ae', injector, SubscriptionPopupComponent);
    }

    override setupVariables(arg: any): void {
    }
}
