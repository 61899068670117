import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Router, RoutesRecognized } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ColorSchemeService {
    private renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);
    private document: Document = inject(DOCUMENT);
    private route: Router = inject(Router);
    private colorSchemePrefix: string = 'color-scheme-';

    private static colorScheme: string = 'light';

    load(): void {
        this.route.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                if (!!data.state.root.firstChild?.data['page_state']) {
                    this.loadStyle(`${data.state.root.firstChild.data['page_state']}-theme-${ColorSchemeService.colorScheme}`);
                }
            }
        });
        this.renderer.addClass(document.body, this.colorSchemePrefix + ColorSchemeService.colorScheme);
    }

    isDarkTheme(): boolean {
        return ColorSchemeService.colorScheme === 'dark';
    }
    currentActive() {
        return ColorSchemeService.colorScheme;
    }
    loadStyle(styleName: string): void {
        let head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];
        let themeLink: HTMLLinkElement = this.document.getElementById('client-theme') as HTMLLinkElement;

        if (themeLink && !themeLink.href.includes(styleName)) {
            themeLink.href = `${environment.host}/${styleName}.css`;
        } else {
            let style: HTMLLinkElement = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${environment.host}/${styleName}.css`;

            head.appendChild(style);
        }
        this.renderer.addClass(document.body, this.colorSchemePrefix + ColorSchemeService.colorScheme);
    }
}
