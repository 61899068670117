import { Routes } from '@angular/router';
import { pageRoutes } from "./pages/utilities/pages-container/pages-container.routes";
import { scriptGuard } from "./guards/script.guard";
import { subscriptionGuard } from "./guards/subscription.guard";
import { tabPageGuardGuard } from './pages/lead/tab-page/guards/tab-page-guard.guard';

export const routes: Routes = [
    {
        path: 'lead/tab',
        loadComponent: () => import('./pages/lead/tab-page/tab-page.component').then((m) => m.TabPageComponent),
        data: { page_state: "robocode_editor"},
        canActivate: [tabPageGuardGuard]
    },
    {
        path: 'lead/smart_buttons_settings',
        loadComponent: () => import('./pages/lead/tab-page/popups/smart-button-settings-popup/smart-button-settings-popup.component').then((m) => m.SmartButtonSettingsPopupComponent),
        data: { page_state: "robocode_editor"},
        canActivate: [tabPageGuardGuard]
    },
    {
        path: 'script/:amo_id/:id',
        loadComponent: () => import('./pages/script/script.component').then((m) => m.ScriptComponent),
        data: { page_state: "robocode_editor"},
        canActivate: [subscriptionGuard, scriptGuard]
    },
    {
        path: 'script/:amo_id',
        loadComponent: () => import('./pages/script/script.component').then((m) => m.ScriptComponent),
        data: { page_state: "robocode_editor"},
        canActivate: [scriptGuard]
    },
    {
        path: '',
        loadComponent: () => import('./pages/utilities/pages-container/pages-container.component').then((m) => m.PagesContainerComponent),
        children: pageRoutes,
        canActivate: [subscriptionGuard]
    },
    {
        path: '**',
        loadComponent: () => import('./pages/utilities/pages-container/pages-container.component').then((m) => m.PagesContainerComponent),
        children: pageRoutes,
    }
];
