import { Routes } from "@angular/router";
import { smartButtonOpenModalGuard } from "./guards/smart-button-open-modal.service";

export const smartButtonRoutes: Routes = [
    {
        path: 'button_settings',
        data: { page_state: "robocode_smart_button_settings"},
        loadComponent: () => import("./popups/smart-button-settings-popup/smart-button-settings-popup.component").then((m) => m.SmartButtonSettingsPopupComponent),
        canActivate: [smartButtonOpenModalGuard]
    },
    {
        path: 'condition_settings',
        data: { page_state: "robocode_smart_button_container_condition_settings"},
        loadComponent: () => import("./popups/smart-button-condition-container-settings/smart-button-condition-container-settings.component").then((m) => m.SmartButtonConditionContainerSettingsComponent),
        canActivate: [smartButtonOpenModalGuard],
        children: [
            {
                path: 'button_condition_settings',
                data: { page_state: "robocode_smart_condition_button_settings"},
                loadComponent: () => import("./popups/smart-button-condition-settings/smart-button-condition-settings.component").then((m) => m.SmartButtonConditionSettingsComponent),
                canActivate: [smartButtonOpenModalGuard]
            }
        ]
    }
];
