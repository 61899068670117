import { Routes } from "@angular/router";
import { scriptsPageGuard } from "../../scripts/guards/scripts-page.guard";

export const pageRoutes: Routes = [
    {
        path: 'scripts',
        loadComponent: () => import('../../scripts/scripts.component').then((m) => m.ScriptsComponent),
        canActivate: [scriptsPageGuard]
    },
    {
        path: 'variables',
        loadComponent: () => import('../../variables/variables.component').then((m) => m.VariablesComponent),
    },
    {
        path: 'hooks',
        loadComponent: () => import('../../hooks/hooks.component').then((m) => m.HooksComponent),
    },
    {
        path: '',
        redirectTo: '/scripts',
        pathMatch: 'full' }
];
