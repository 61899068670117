import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from "@angular/platform-browser/animations";
import { routes } from './app.routes';
import { ApiModule } from './api/api.module';
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { headersInterceptor } from "./interceptors/headers/headers.interceptor";
import { environment } from '../environments/environment';


export const appConfig: ApplicationConfig = {
  providers: [
      provideRouter(routes, withComponentInputBinding()),
      provideAnimations(),
      provideHttpClient(
          withInterceptors([headersInterceptor])
      ),
      importProvidersFrom(ApiModule.forRoot({ rootUrl: environment.apiUrl})),
  ]
};
