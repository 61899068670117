import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SmartButtonOpenModalService {

    constructor() {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (state.url.includes('smart_buttons_settings')) {
            return true;
        }
        return false;
    }
}
export const smartButtonOpenModalGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(SmartButtonOpenModalService).canActivate(route, state);
};
