import { CanActivateFn } from '@angular/router';
import { combineLatest, filter, first, from, mergeMap, of } from 'rxjs';
import { FontLoaderService } from '../services/font-loader.service';
import { inject } from '@angular/core';

export const scriptsPageGuard: CanActivateFn = (route, state) => {
    let fontLoaderService: FontLoaderService = inject(FontLoaderService);
    return combineLatest([
            from(fontLoaderService.loadFont('TT Norms Pro Mono Trial Variable', '/assets/fonts/TTNormsProMonoTrialVariable.ttf'))
        ]).pipe(
            filter(([isFontLoaded]: [boolean]) => {
                return (isFontLoaded);
            }),
            first(),
            mergeMap(() => {
                return of(true);
            })
        )
};
