import { CanActivateFn } from '@angular/router';
import {combineLatest, filter, mergeMap, of, take} from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { inject } from "@angular/core";
import { EnvironmentStore } from "../stores/environment/environment.store";
import { SubscriptionPopupService } from "../ui/subscription-popup/services/subscription-popup.service";

export const subscriptionGuard: CanActivateFn = (route, state) => {
    window.focus();
    let environmentStore = inject(EnvironmentStore);
    let subscriptionPopupService: SubscriptionPopupService = inject(SubscriptionPopupService);

    environmentStore.loadWidgetData();

    return combineLatest([
        toObservable(environmentStore.subscription.expiration),
    ]).pipe(
        filter(([expirationTime]: [expirationTime: number]) => {
            if (!!expirationTime) {
                if (expirationTime * 1000 < new Date().getTime()) {
                    subscriptionPopupService.show();
                } else {
                    return true;
                }
            }
            return false;
        }),
        take(1),
        mergeMap(() => {
            return of(true);
        })
    )
};
