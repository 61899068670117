import { CanActivateFn } from '@angular/router';
import { combineLatest, filter, first, from, mergeMap, of, Subscription, tap } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { inject } from "@angular/core";
import { GlobalEventsService } from "../global-events/global-events.service";
import { ScenarioConstructorDataStore } from "../stores/scenario-constructor-data/scenario-constructor-data.store";
import { GroupsStore } from "../stores/groups/groups.store";
import { HooksStore } from "../stores/hooks/hooks.store";
import { EnvironmentStore } from "../stores/environment/environment.store";
import { patchState } from "@ngrx/signals";
import { ScriptsStore } from "../stores/scripts/scripts.store";
import { DataCacheDbController } from '../pages/script/data-cache-db.controller';
import { PostMessageService } from '../global-events/PostMessage/post-message.service';
import { FontLoaderService } from '../pages/scripts/services/font-loader.service';

export const scriptGuard: CanActivateFn = (route, state) => {
    window.focus();
    let environmentStore = inject(EnvironmentStore);
    let globalEventsService: GlobalEventsService = inject(GlobalEventsService);
    let scenarioConstructorDataStore = inject(ScenarioConstructorDataStore);
    let groupsStore = inject(GroupsStore);
    let hooksStore = inject(HooksStore);
    let scriptsStore = inject(ScriptsStore);
    let postMessageService = inject(PostMessageService);
    let fontLoaderService: FontLoaderService = inject(FontLoaderService);

    scenarioConstructorDataStore.load();
    hooksStore.loadHooks();
    groupsStore.loadGroups();
    scriptsStore.loadScenarios();

    if ('id' in route.params) {
        patchState(scriptsStore, { selected_id: route.params['id'] });
    } else {
        patchState(scriptsStore, { isLoaded: true });
    }

    let keydownSubscription: Subscription = globalEventsService.getGlobalKeydownEventOnlyThis().pipe(
        filter((event: KeyboardEvent) => event.key === 'Escape'),
        tap(() => {
            postMessageService.sendMessage(['close_modal_scenario_by_id', '']);
        })
    ).subscribe();

    return combineLatest([
        toObservable(scenarioConstructorDataStore.isLoaded),
        toObservable(hooksStore.isLoaded),
        toObservable(groupsStore.isLoaded),
        toObservable(scriptsStore.isLoaded),
        from(fontLoaderService.loadFont('Source Code Pro', '/assets/fonts/SourceCodePro-Regular.ttf'))
    ]).pipe(
        filter(([isConstructorLoaded, isHooksLoaded, isGroupsLoaded, isScriptLoaded, isFontLoaded]: [isGroupsLoaded: boolean, isHooksLoaded: boolean, isScenarioLoaded: boolean, isScriptLoaded: boolean, isFontLoaded: boolean]) => {
            return (isConstructorLoaded && isHooksLoaded && isGroupsLoaded && isScriptLoaded && isFontLoaded);
        }),
        first(),
        tap(() => {
            globalEventsService.disableOnlyThisMode();
            keydownSubscription.unsubscribe();
            patchState(environmentStore, { isDataLoaded: true });
        }),
        mergeMap(() => {
            return of(true);
        })
    )
};
